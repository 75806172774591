<template>
    <b-container>
        <app-collapse class="pb-2">
            <app-collapse-item title="Búsqueda"
                               class="p-2"
                               :is-visible="isVisible">
                <b-row>
                    <h4 class="card-title"></h4>
                            <!-- fecha desde -->
                            <b-col md="6">
                                <b-form-group>
                                    <h5 class="font-weight-bold">
                                        Desde la fecha
                                    </h5>
                                    <div class="input-group">
                                        <flat-pickr
                                            v-model="filter.fecha_inicio"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="fecha_desde"
                                            reset-button
                                        />
                                        <div class="input-group-append">
                                            <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                                <feather-icon
                                                    icon="XIcon"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <!-- fecha desde fin -->

                            <!-- fecha hasta -->
                            <b-col md="6">
                                <b-form-group>
                                    <h5 class="font-weight-bold">
                                        Hasta la fecha
                                    </h5>
                                    <div class="input-group">
                                        <flat-pickr
                                            v-model="filter.fecha_termino"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="fecha_hasta"
                                            reset-button
                                        />
                                        <div class="input-group-append">
                                            <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                                <feather-icon
                                                    icon="XIcon"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <!-- fecha hasta fin -->

                            <!-- botón buscar inicio -->
                            <b-col md="12">
                                <div class="text-right mt-2">
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        @click="searchButton(filter)">
                                        <feather-icon icon="SearchIcon" class="mr-50" />
                                        <span class="align-middle">Buscar</span>
                                    </b-button>
                                </div>
                            </b-col>
                            <!-- botón buscar fin -->
                </b-row>
            </app-collapse-item>
        </app-collapse>

            <!-- tabla inicio -->
            <b-overlay :show="loading">
                <b-card>
                    <h4 class="card-title">Reporte bono Contact Center</h4>
                    <div v-if="visibleReport">
                        <!-- botón exportar inicio -->
                        <b-row class="pr-1 pl-1">
                            <b-col sm="12" md="12" class="my-1 text-left">
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        class="ml-1"
                                        @click="exportExcelReporteBonoContact"
                                    >
                                        <feather-icon
                                            icon="DownloadIcon"
                                            class="mr-50"
                                        />
                                        <span class="align-middle">Exportar</span>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- botón exportar fin -->

                        <!-- mostrar filas por página, paginado - inicio -->
                        <b-row class="pl-1">
                            <b-col sm="3" md="4"  class="mt-2">
                                <div class="d-flex align-items-center mb-1 mb-md-0">
                                    <label>Mostrar</label>
                                    <b-form-select
                                        @change="onChangePerPage"
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                        class="mx-50 col-md-2"
                                    ></b-form-select>
                                    <label>filas por página</label>
                                </div>
                            </b-col>
                            <b-col  cols="4" class="mt-2">
                                <div v-if="totalRows > 0">
                                    <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                                </div>
                            </b-col>

                            <b-col cols="4">
                                <b-pagination
                                    @change="onPageChange"
                                    :total-rows="totalRows"
                                    :value="page"
                                    :per-page="perPage"
                                    align="right"
                                    class="mt-2 mr-1"
                                    aria-controls="my-table"
                                />
                            </b-col>
                        </b-row>
                        <!-- mostrar filas por página, paginado - fin -->

                        <!-- tabla inicio -->
                        <b-col cols="12" v-if="showTable">
                            <b-table
                                striped
                                hover
                                small
                                :per-page="perPage"
                                :items="listado"
                                :fields="fields"
                                responsive
                                class="mb-0"
                                id="my-table"
                                ref="table"
                                show-empty
                            >
                                <template #empty="scope">
                                    <div class="text-center">No existen resultados</div>
                                </template>
                            </b-table>
                        </b-col>
                        <!-- tabla fin -->
                    </div>
                </b-card>
            </b-overlay>
    </b-container>
</template>

<script>
    import {
        BTable,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BButton,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import { parseDate } from "echarts/lib/util/number";
    import { falseFn } from "leaflet/src/core/Util";
    import axios from "axios";

    export default {
        name: "ReporteBonoContact",
        components: {
            AppCollapseItem,
            AppCollapse,
        },

        directives: {
            Ripple,
        },
        data(){
            return {
                showTable: true,
                visibleReport: false,
                perPage: 50,
                reportType: 1,
                currentPageV: 1,
                page: 1,
                list:[],
                columnsData:[],
                dataDetalle:[],
                isVisible:true,
                filter: {
                    fecha_inicio: null,
                    fecha_termino: null,
                    fields:[]
                },

                sortField: 'ficha-rut',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                sortDesc: false,
                showColumn: false,
                showColumnText: 'Mostrar Columnas',
                fields: [
                    { key: "ficha-rut", label: "ficha-rut", display: true },
                    { key: "tutor", label: "tutor", display: true },
                    { key: "modalidad", label: "modalidad", display: true },
                    { key: "aplicacion", label: "aplicacion", display: true },
                    { key: "telefono1", label: "telefono1", display: true },
                    { key: "telefono2", label: "telefono2", display: true },
                    { key: "correo1", label: "correo1", display: true },
                    { key: "correo2", label: "correo2", display: true },
                    { key: "id_usuario", label: "id_usuario", display: true },
                    { key: "holding", label: "holding", display: true },
                    { key: "empresa", label: "empresa", display: true },
                    { key: "estado", label: "estado", display: true },
                    { key: "fecha_inicio", label: "fecha_inicio", display: true },
                    { key: "fecha_termino", label: "fecha_termino", display: true },
                    { key: "fecha_cierre", label: "fecha_cierre", display: true },
                    { key: "situacion", label: "situacion", display: true },
                    { key: "costo", label: "costo", display: true },
                    { key: "nota_final", label: "nota_final", display: true },
                ],
                pageOptions: [50, 100, 500, 1000],
                transProps: {
                // Transition name
                name: 'flip-list'
                },
            }
        },

        methods: {
          getList(){
              let filterData = { ...{
                  'sort_by': this.sortField,
                      'page': this.page,
                      'perPage': this.perPage },
                  ...this.filter
              }
              this.$store.dispatch('reporteBonoContact/getList', filterData)
          },
          onPageChange(page) {
              this.page = page;
              //this.currentPageV = page
              this.getList();
          },
          buscar_data(filter){
              this.filter = filter;
              this.isVisible = false;
              this.page = 1;
              this.getList();
          },
          exportExcelReporteBonoContact(){
              if(this.listado.length === 0) {
                  this.$bvToast.toast('no existen datos a exportar', {
                      title: `Error al exportar datos`,
                      variant: "danger",
                      solid: true
                  })
              }else {
                  this.excelExportData();
              }
          },
            excelExportData() {
                let filterData = { ...{
                        'sort_by': this.sortField,
                        'page': this.page,
                        'perPage': this.perPage,
                        'exportInBack': false
                    },
                    ...this.filter,
                    fieldDoc: this.fields
                }
                if(this.totalRows <= 50000){
                    this.$bvToast.toast('La descarga comenzará en un momento',{
                        title:`Alerta`,
                        variant: 'info',
                        solid: true,
                    })
                    this.$store.dispatch('reporteBonoContact/excelExportData', filterData)
                }else if(this.totalRows > 50000){
                    this.$bvToast.toast('En los próximos minutos el reporte será enviado a su correo',{
                        title:`Alerta`,
                        variant: 'info',
                        solid: true,
                    })
                    this.$store.dispatch('reporteBonoContact/mailSendData', filterData)
                }
            },
            searchButton(filter){
              if(this.filter.fecha_inicio == null || this.filter.fecha_termino == null) {
                  this.$bvToast.toast('Debe seleccionar un rango de fechas',{
                      title:`Alerta`,
                      variant: 'danger',
                      solid: true,
                  })
              } else if(parseDate(this.filter.fecha_inicio) > parseDate(this.filter.fecha_termino)){
                  this.$bvToast.toast('La fecha de inicio debe ser mayor a la fecha de término',{
                      title:`Alerta`,
                      variant: 'danger',
                      solid: true,
                  })
              }else if(this.filter.fecha_inicio == "" || this.filter.fecha_termino == ""){
                  this.$bvToast.toast('Debe seleccionar un rango de fechas',{
                      title:`Alerta`,
                      variant: 'danger',
                      solid: true,
                  })
              } else {
                  this.buscar_data(filter)
                  this.visibleReport = true
              }
            },
        },

        computed: {
            listado(){
              return this.$store.state.reporteBonoContact.list;
            },
            fecha_desde(){
                const date = new Date();
                date.setMonth(date.getMonth() -3);
                const dateBefore = date.toISOString().split('T')[0];
                this.filter.fecha_inicio = dateBefore;
                return{
                    locale: require(`flatpickr/dist/l10n/es.js`).default[`es`],
                    //dateFormat: "d/m/y",
                    dateFormat: "Y-m-d",
                    wrap: true,
                    defaultDate: dateBefore,
                }
            },
            fecha_hasta(){
                const date = new Date();
                const dateYmd = date.toISOString().split('T')[0];
                this.filter.fecha_termino = dateYmd;
                return{
                    locale: require(`flatpickr/dist/l10n/es.js`).default[`es`],
                    //dateFormat: "d/m/y",
                    dateFormat: "Y-m-d",
                    wrap: true,
                    defaultDate: dateYmd,
                }
            },
            loading(){
                return this.$store.state.reporteBonoContact.loading;
            },
            totalRows() {
                return this.$store.state.reporteBonoContact.rowsTotal;
            },
            to() {
                return this.$store.state.reporteBonoContact.to;
            },
            from() {
                return this.$store.state.reporteBonoContact.from;
            },
        },
        mounted() {
            //this.getList()
        }
    };
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
    margin-top: 0.5rem !important;
}

.btn-clear {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0px 3px 3px 0px!important;
    color: #6e6b7b;
}

</style>
